// import React, { useEffect, useState } from 'react';
// import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
// import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import logo from '../Images/kama-logo/kama-main-logo.png';
// import './NavigationBar.css';

// const NavigationBar = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Initialize the language state from the URL path or default to 'en'
//   const [language, setLanguage] = useState(() => {
//     const pathLanguage = location.pathname.split('/')[1];
//     return pathLanguage === 'ar' || pathLanguage === 'en' ? pathLanguage : 'en';
//   });

//   const [expanded, setExpanded] = useState(false);
//   const [openDropdown, setOpenDropdown] = useState(null); // Manage which dropdown is open

//   useEffect(() => {
//     // Store the selected language in session storage
//     sessionStorage.setItem('lan', language);
//   }, [language]);

//   const handleToggle = () => {
//     setExpanded(!expanded);
//   };

//   const closeNav = () => {
//     setExpanded(false);
//     setOpenDropdown(null);
//   };

//   const toggleLanguage = () => {
//     const newLanguage = language === 'en' ? 'ar' : 'en';
//     setLanguage(newLanguage);
//     const newPath = `/${newLanguage}${location.pathname.slice(3)}`;
//     navigate(newPath); // React Router's navigate function
//   };

  
//   // Define the navigation links
//   const navLinks = [
//     { path: '/home', label: { en: 'Home', ar: 'الصفحة الرئيسية' } },
//     { path: '/market', label: { en: 'Market', ar: 'الأسواق' } },
//     {
//       path: '/platforms',
//       label: { en: 'Platform', ar: 'المنصة' },
//       dropdown: true,
//       items: [{ path: '/metatrader5', label: { en: 'Metatrader 5', ar: 'ميتاتريدر 5' } }],
//     },
//     { path: '/about', label: { en: 'About', ar: 'مركز كاما التعليمى' } },
//     { path: '/contact', label: { en: 'Contact', ar: 'مركز كاما التعليمى' } },
//   ];

//   // Reverse the order of the links if the language is Arabic
//   const displayedLinks = language === 'ar' ? [...navLinks].reverse() : navLinks;

//   return (
//     <Navbar expand="lg" className="navbar-expand-lg" expanded={expanded}>
//       <Container className={language === 'ar' ? 'flex-row-reverse' : ''}>
//         <Navbar.Brand as={Link} to={`/${language}`}>
//           <img src={logo} className="logo" alt="logo" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
//           {expanded ? <FaTimes className="toggle-icon" /> : <FaBars className="toggle-icon" />}
//         </Navbar.Toggle>
//         <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
//           {/* Language Toggle */}
//           {language === 'ar' && (
//             <div className="language-toggle" onClick={toggleLanguage}>
//               <div className={`toggle-switch ${language === 'en' ? 'english' : 'arabic'}`}>
//                 <div className="toggle-thumb">{language === 'en' ? 'EN' : 'AR'}</div>
//               </div>
//             </div>
//           )}
//           <Nav className="menu-items mx-auto">
//             {displayedLinks.map((link, index) =>
//               link.dropdown ? (
//                 <NavDropdown
//                   key={index}
//                   title={
//                     <span>
//                       {link.label[language]}{' '}
//                       <FaChevronDown
//                         className={`dropdown-icon ${openDropdown === link.path ? 'open' : ''}`}
//                       />
//                     </span>
//                   }
//                   id={`${link.path.slice(1)}-dropdown`}
//                   className="mx-3 custom-dropdown"
//                   show={openDropdown === link.path}
//                   onMouseEnter={() => setOpenDropdown(link.path)}
//                   onMouseLeave={() => setOpenDropdown(null)}
//                 >
//                   {link.items.map((item, subIndex) => (
//                     <NavDropdown.Item
//                       key={subIndex}
//                       as={Link}
//                       onClick={closeNav}
//                       to={`/${language}${item.path}`}
//                     >
//                       {item.label[language]}
//                     </NavDropdown.Item>
//                   ))}
//                 </NavDropdown>
//               ) : (
//                 <Nav.Link
//                   key={index}
//                   as={Link}
//                   onClick={closeNav}
//                   className="nav-link mx-3"
//                   to={`/${language}${link.path}`}
//                 >
//                   {link.label[language]}
//                 </Nav.Link>
//               )
//             )}
//           </Nav>
//           {/* Language Toggle for English */}
//           {language === 'en' && (
//             <div className="language-toggle" onClick={toggleLanguage}>
//               <div className={`toggle-switch ${language === 'en' ? 'english' : 'arabic'}`}>
//                 <div className="toggle-thumb">{language === 'en' ? 'EN' : 'AR'}</div>
//               </div>
//             </div>
//           )}
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default NavigationBar;




import React, { useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import logo from '../Images/kama-logo/kama-main-logo.png';
import './NavigationBar.css';
import { useNavigate, useLocation } from 'react-router-dom';

const NavigationBar = ({ onNavClick, lang }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [language, setLanguage] = React.useState(sessionStorage.getItem('language') || 'en');
  const [expanded, setExpanded] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(null);

  useEffect(() => {
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const closeNav = () => {
    setExpanded(false);
    setOpenDropdown(null);
  };

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    setLanguage(newLanguage);
    sessionStorage.setItem('language', newLanguage);
    lang(newLanguage); // Call the function passed as prop to update the language

    const currentPath = location.pathname;

    if (newLanguage === 'ar') {
      if (currentPath.startsWith('/en/contact')) {
        navigate('/ar/contact');
      } else if (currentPath.startsWith('/en')) {
        navigate('/ar');
      }
    } else {
      if (currentPath.startsWith('/ar/contact')) {
        navigate('/en/contact');
      } else if (currentPath.startsWith('/ar')) {
        navigate('/en');
      }
    }
  };

  const navLinks = [
    { section: 'home', label: { en: 'Home', ar: 'الصفحة الرئيسية' }, isExternal: false },
    // { section: 'market', label: { en: 'Market', ar: 'الأسواق' }, isExternal: false },
    { section: 'platform', label: { en: 'Services', ar: 'الخدمات' }, isExternal: false },
    { section: 'about', label: { en: 'Why Partner', ar: 'لماذا الشراكة' }, isExternal: false },
    { section: 'contact', label: { en: 'Contact', ar: 'اتصل بنا' }, isExternal: true },
  ];

  const displayedLinks = language === 'ar' ? [...navLinks] : navLinks;

  const handleNavClick = (section, isExternal) => {
    if (isExternal) {
      onNavClick(section);
      navigate(`/${language}/contact`);
    } else {
      onNavClick(section);
    }
  };

  return (
    <Navbar expand="lg" className={`custom-navbar ${language === 'ar' ? 'rtl' : 'ltr'}`} expanded={expanded}>
      <Container>
        <Navbar.Brand onClick={() => handleNavClick('home')}>
          <img src={logo} className="logo" alt="logo" />
        </Navbar.Brand>
        <div className='d-md-block d-lg-none'>
          <div className="language-toggle" onClick={toggleLanguage}>
            <div className={`toggle-switch ${language === 'en' ? 'english' : 'arabic'}`}>
              <div className="toggle-thumb">{language === 'en' ? 'EN' : 'AR'}</div>
            </div>
          </div>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
          {expanded ? <FaTimes className="toggle-icon" /> : <FaBars className="toggle-icon" />}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
          <Nav className="menu-items mx-auto">
            {displayedLinks.map((link, index) =>
              link.dropdown ? (
                <NavDropdown
                  key={index}
                  title={
                    <span>
                      {link.label[language]}{' '}
                      <FaChevronDown className={`dropdown-icon ${openDropdown === link.section ? 'open' : ''}`} />
                    </span>
                  }
                  id={`${link.section}-dropdown`}
                  className="mx-3 custom-dropdown"
                  show={openDropdown === link.section}
                  onMouseEnter={() => setOpenDropdown(link.section)}
                  onMouseLeave={() => setOpenDropdown(null)}
                >
                  {link.items.map((item, subIndex) => (
                    <NavDropdown.Item
                      key={subIndex}
                      onClick={() => handleNavClick(item.section, item.isExternal)}
                    >
                      {item.label[language]}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link
                  key={index}
                  onClick={() => handleNavClick(link.section, link.isExternal)}
                  className="nav-link mx-3"
                >
                  {link.label[language]}
                </Nav.Link>
              )
            )}
          </Nav>
        </Navbar.Collapse>
        <div className='d-none d-lg-block'>
          <div className="language-toggle" onClick={toggleLanguage}>
            <div className={`toggle-switch ${language === 'en' ? 'english' : 'arabic'}`}>
              <div className="toggle-thumb">{language === 'en' ? 'EN' : 'AR'}</div>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
