import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import FloatingPhoneCall from './components/Widgets/PhoneCall/FloatingPhoneCall';
import NavigationBar from './components/Navbar/NavigationBar';
import LandingPageRouting from './components/English/Landing/LandingPageRouting';
import ContactUsRouting from './components/English/Contact/ContactUsRouting';
import ArabicLandingRouting from './components/Arabic/ArabicLanding/ArabicLandingRouting';
import ArabicContactUsRouting from './components/Arabic/ArabicContact/ArabicContactUsRouting';

const App = () => {
  const [targetSection, setTargetSection] = useState('');
  const [lang, setLang] = useState(sessionStorage.getItem('language') || 'en');

  const handleNavClick = (section) => {
    setTargetSection(section);
  };

  return (
    <div className='App'>
      {/* <FloatingWhatsApp
        phoneNumber="8667326535"
        accountName="Kama-Capital"
        message="Hello! How can we help you?"
        className='floating-whatsapp-button'
      /> */}
      <FloatingPhoneCall />
      <iframe 
        allowtransparency="true" 
        src="https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=en"
        title="ticker tape TradingView widget" 
        lang="en" 
        className='floating-trading-view'>
      </iframe>
      <BrowserRouter>
        <Routes>
          {lang === 'ar' && (
            <>
              <Route
                path="/"
                element={<ArabicLandingRouting onNavClick={handleNavClick} targetSection={targetSection} lang={setLang} />}
              />
              <Route
                path="/ar/contact"
                element={<ArabicContactUsRouting onNavClick={handleNavClick} targetSection={targetSection} lang={setLang} />}
              />
            </>
          ) }
           
              <Route
                path="/"
                element={<LandingPageRouting onNavClick={handleNavClick} targetSection={targetSection} lang={setLang} />}
              />
              <Route
                path="/en/contact"
                element={<ContactUsRouting onNavClick={handleNavClick} targetSection={targetSection} lang={setLang} />}
              />
           
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
