import React, { useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
const NavigationBar = lazy(()=>import('../../Navbar/NavigationBar'))
const ArabicLandingHero = lazy(()=>import('./ArabicLandingHero/ArabicLandingHero'))
const ArabicLandingMarketInfo = lazy(()=>import('./ArabicLandingMarketInfo/ArabicLandingMarketInfo'))
const ArabicLandingPlatformMt5 = lazy(()=>import('./ArabicPlatform/ArabicLandingPlatformMt5'))
const ArabicLandingWhyInvest = lazy(()=>import('./ArabicLandingWhyInvest/ArabicLandingWhyInvest'))
const ArabicLandingKamaTeam = lazy(()=>import('./ArabicLandingKamaTeam/ArabicLandingKamaTeam'))
const ArabicFooter = lazy(()=>import('../ArabicFooter/ArabicFooter'))

const ArabicLandingRouting = React.memo(({ onNavClick, targetSection,lang }) => {
  const navigate = useNavigate();
  const homeRef = useRef(null);
  const platformRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  
  const sectionRefs = {
    home: homeRef,
    platform: platformRef,
    about: aboutRef,
    contact: contactRef,
  };

  useEffect(() => {
    if (targetSection && sectionRefs[targetSection]?.current) {
      const navbarHeight = document.querySelector('.custom-navbar')?.offsetHeight || 0; // Get the height of the navbar
      const targetPosition = sectionRefs[targetSection].current.offsetTop - navbarHeight; // Subtract navbar height

      // Scroll to the target section, considering navbar height
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  }, [targetSection, sectionRefs]);

  const handleNavClick = (section, isExternal) => {
    onNavClick(section);
    if (isExternal) {
      navigate('/en/contact');
    } else {
      navigate('/');
    }
  };

  const scrollToPlatform = () => {
    const navbarHeight = document.querySelector('.custom-navbar')?.offsetHeight || 0;
    if (platformRef.current) {
      const platformPosition = platformRef.current.offsetTop - navbarHeight; // Offset by navbar height
      window.scrollTo({
        top: platformPosition,
        behavior: "smooth",
      });
    }
  };
      
      
  return (
    <div className="landing-page-container" >
    <div className="navbarPositions">
      <Suspense fallback={<div></div>}>
        <NavigationBar onNavClick={handleNavClick} lang={lang}/>
      </Suspense>
    </div>
    <section ref={homeRef} className="section">
      <Suspense fallback={<div></div>}>
      <ArabicLandingHero  onExploreClick={scrollToPlatform} />
      <ArabicLandingKamaTeam/>
      </Suspense>
    </section>
    {/* <section ref={marketRef} className="section">
        <Suspense fallback={<div></div>}>
          <ArabicLandingMarketInfo />
        </Suspense>
      </section> */}
      <section ref={platformRef} className="section">
        <Suspense fallback={<div></div>}>
          <ArabicLandingPlatformMt5 />
        </Suspense>
      </section>
      <section ref={aboutRef} className="section">
        <Suspense fallback={<div></div>}>
          <ArabicLandingWhyInvest/>
          
        </Suspense>
      </section>
      <Suspense fallback={<div></div>}>
        <ArabicFooter />
      </Suspense>
  </div>
  )
})

export default React.memo(ArabicLandingRouting);
